<template>
    <div>博客</div>
</template>

<script>
export default {
    
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>